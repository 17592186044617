<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <v-card class="card">
      <v-card-title class="heading justify-center">
        Search Catalog
      </v-card-title>
      <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
      <v-row class="px-5 pt-4">
        <v-col cols="12" md="6" sm="6">
          <v-autocomplete v-model="selectedseries" outlined label="Select Series" :items="serieslist" item-text="name"
            item-value="id" @change="
              setValue();
            seriescount();
            "></v-autocomplete>
          <b>Series Wise Book Count {{ seriesbookcount }}</b>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-autocomplete v-model="selecteddepartment" outlined label="Select Department" :items="departmentlist"
            item-text="name" item-value="id" @change="departmentcount()"></v-autocomplete>
          <b> Department Wise Book Count {{ departmentbookcount }}</b>
        </v-col>
      </v-row>
      <v-row class="px-6" justify="center" align="center">
        <v-divider></v-divider>
      </v-row>
      <v-row class="" justify="center" align="center" style="padding-left: 20px">
        <v-col cols="12" md="3" sm="3" v-for="item in [
          'Author',
          'Accession No',
          'Subject',
          'Call No',
          'Book Title',
          'Keyword',
          'Medium',
          'Publisher',
        ]" :key="item">
          <div>
            <input style="width: 15px; height: 15px" type="radio" v-bind:value="item" v-model="picked"
              @change="setValue" />
            &nbsp;
            <label>{{ item }}</label>
          </div>
          <!--<input style="width:15px;height:15px;"  type="radio" v-bind:value="item" v-model="picked"  @change="setValue"> 
                    &nbsp;
                    <label>{{item}}</label>-->
        </v-col>
      </v-row>
      <v-row class="" justify="center" align="center">
        <v-col cols="12" md="4" sm="4"></v-col>

        <v-combobox v-if="picked == 'Book Title'" style="padding: 0px; margin: 0px" v-model="selectedtitle1"
          :items="allBookInfo" item-text="displayname" placeholder="Select Book" outlined hide-no-data hide-details dense
          @input.native="onload1($event.srcElement.value)" @change="settext()"></v-combobox>
        <v-combobox v-else-if="picked == 'Keyword'" style="padding: 0px; margin: 0px" v-model="selectedkeyword1"
          :items="allBookInfo" item-text="displayname" placeholder="Select Keyword" outlined hide-no-data hide-details
          dense @input.native="onloadkeyword($event.srcElement.value)" @change="settextK()"></v-combobox>
        <v-combobox v-else-if="picked == 'Subject'" style="padding: 0px; margin: 0px" v-model="selectedsubject1"
          :items="allBookInfo" item-text="displayname" placeholder="Select Subject" outlined hide-no-data hide-details
          dense @input.native="onloadsubject($event.srcElement.value)" @change="settextS()"></v-combobox>
        <v-combobox v-else-if="picked == 'Medium'" style="padding: 0px; margin: 0px" v-model="selectedmedium"
          :items="mediumlist" item-text="name" placeholder="Select Medium" outlined return-object dense
          @input.native="setmedium($event.srcElement.value)" @change="settext1()"></v-combobox>

        <v-combobox v-else-if="picked == 'Author'" style="padding: 0px; margin: 0px" v-model="selectedauthor1"
          :items="authorlist_new" item-text="name" placeholder="Select Author" outlined return-object dense
          @input.native="onload2($event.srcElement.value)" @change="settext2()"></v-combobox>

        <v-text-field v-else class="" :placeholder="picked" v-model="keyword" outlined dense></v-text-field>

        <v-col cols="12" md="4" sm="4"></v-col>
      </v-row>
      
      <v-row justify="center" align="center">
        <v-btn class="m-2" color="info" @click="searchBykeyword(true)">Search</v-btn>
      </v-row>


      <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
      <v-data-table v-if="utype == 'Employee'" :headers="headers" :item-class="row_classes" :items="bookList1"
        item-key="access_no" class="elevation-1 mt-5" :search="search" @current-items="getFiltered">
        <v-spacer></v-spacer>
        <template v-slot:item.acc_no="props">
          <v-edit-dialog large v-if="props.item.is_img" transition save-text="" cancel-text="Close" style="width:100%;"
            @cancel="cancelphoto" @open="openphoto(props.item)" @close="closephoto">
            <v-icon class="purple--text">mdi-eye</v-icon>

            <template v-slot:input>
              <div class="mt-4 text-h6">
                <v-img height="200" width="200" :src="image_url"></v-img>
              </div>

            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-col cols="6"></v-col>
            <v-spacer></v-spacer>
            <download-excel class="ml-5" :data="filterArr" :fields="fields" worksheet="bookItemlist" name="BookItem.xls">
              <v-btn color="success" dark class="mb-2">
                Export<v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>

            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-form v-model="valid" ref="form">
                  <v-card-title class="heading">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="fields">
                          <label>Accession Number</label>
                          <v-text-field dense outlined v-model="editedItem.access_no" persistent-hint :disabled="!inSave"
                            :error="errorMsg.access_no ? true : false" :error-messages="errorMsg.access_no ? 'Required' : ''
                              " :rules="[(v) => !!v || 'required']"></v-text-field>
                        </v-col>

                        <v-row v-if="utype == 'Employee'" justify="center" class="ma-5">
                          <router-link :to="{
                            name: 'issue-book',
                            params: {
                              assno: editedItem.access_no,
                              id: editedItem.id,
                            },
                          }" class="root">
                            <v-btn dark class="primary"> ISSUE BOOK</v-btn>
                          </router-link>

                          <router-link :to="{
                            name: 'reserve-book',
                            params: {
                              assno: editedItem.isbn,
                              id: editedItem.id,
                            },
                          }" class="root">
                            <v-btn dark class="primary"> RESERVE BOOK</v-btn>
                          </router-link>
                        </v-row>

                        <v-col cols="12" class="fields">
                          <label>Rack No</label>
                          <v-autocomplete dense outlined @change="getRackBlockNo()" :items="racklist" item-value="rackId"
                            item-text="rackNo" v-model="editedItem.rackId" persistent-hint></v-autocomplete>
                        </v-col>

                        <v-col cols="12" class="fields">
                          <label>Block No</label>
                          <v-autocomplete dense outlined :items="blockList" @change="checkRackAvailability()"
                            item-value="block_no" item-text="block_no" v-model="editedItem.block_no"
                            persistent-hint></v-autocomplete>
                        </v-col>
                        <p style="color: red" v-if="!isRackAvailable">
                          Selected Rack and Block not available!!!
                        </p>

                        <v-col cols="12" class="fields">
                          <label>Withdrawal No</label>
                          <v-text-field dense outlined v-model="editedItem.w_no" item-text="roleLink_list_id"
                            item-value="id" persistent-hint></v-text-field>
                        </v-col>

                        <v-col cols="12" class="fields">
                          <label>Withdrawal Date</label>
                          <v-menu v-model="showPicker5" :close-on-content-click="false" transition="scale-transition"
                            offset-y full-width max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="editedItem.w_date" placeholder="Select Date" hint="YYYY/MM/DD"
                                persistent-hint dense readonly outlined v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.w_date" no-title
                              @input="showPicker1 = false"></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" class="fields">
                          <label>Publication Place</label>
                          <v-text-field dense outlined v-model="editedItem.p_place" item-text="roleLink_list_id"
                            item-value="id" persistent-hint></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                    <v-btn class="button" @click="save()" :disabled="!valid || !isRackAvailable">Save</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <br />
          <hr />
          <v-row justify="space-between" class="pr-10 pa-4">
            <div class="">
              Total No of Available Copies:{{ bookList.length }}
            </div>
            <div>
              <span style="
                  width: 20px;
                  height: 20px;
                  color: orange;
                  background-color: orange;
                ">
                XXXX</span> Weed Out
              <span style="
                  width: 20px;
                  height: 20px;
                  color: red;
                  background-color: red;
                ">
                XXXX</span>
              Lost
              <span style="
                  width: 20px;
                  height: 20px;
                  color: #2196f3;
                  background-color: #2196f3;
                ">
                XXXX</span>
              Reserved
              <span style="
                  width: 20px;
                  height: 20px;
                  color: green;
                  background-color: green;
                ">
                XXXX</span>
              Issued
            </div>
          </v-row>

          <tr class="t-row">
            <div>
              <th class="d-flex">
                <span v-for="header in headers" :key="header.text">
                  <span v-if="filters.hasOwnProperty(header.value)">
                    <v-autocomplete class="m-2" flat hide-details small dense outlined :label="header.text" multiple
                      clearable :items="columnValueList(header.value)" v-model="filters[header.value]"></v-autocomplete>
                  </span>
                </span>
              </th>
            </div>
          </tr>
        </template>

        <template v-slot:item.action="{ item }">
          <div><v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon></div>
        </template>
      </v-data-table>

      <v-data-table v-else :headers="headersMember" :item-class="row_classes" :items="bookList1" item-key="access_no"
        class="elevation-1 mt-5" :search="search" @current-items="getFiltered">
        <v-spacer></v-spacer>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-col cols="6"></v-col>
            <v-spacer></v-spacer>
            <download-excel class="ml-5" :data="filterArr" :fields="fields" worksheet="bookItemlist" name="BookItem.xls">
              <v-btn color="success" dark class="mb-2">
                Export<v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>

            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-form v-model="valid" ref="form">
                  <v-card-title class="heading">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="fields">
                          <label>Accession Number</label>
                          <v-text-field dense outlined v-model="editedItem.access_no" persistent-hint :disabled="!inSave"
                            :error="errorMsg.access_no ? true : false" :error-messages="errorMsg.access_no ? 'Required' : ''
                              " :rules="[(v) => !!v || 'required']"></v-text-field>
                        </v-col>

                        <v-row v-if="utype == 'Employee'" justify="center" class="ma-5">
                          <router-link :to="{
                            name: 'issue-book',
                            params: {
                              assno: editedItem.access_no,
                              id: editedItem.id,
                            },
                          }" class="root">
                            <v-btn dark class="primary"> ISSUE BOOK</v-btn>
                          </router-link>

                          <router-link :to="{
                            name: 'reserve-book',
                            params: {
                              assno: editedItem.isbn,
                              id: editedItem.id,
                            },
                          }" class="root">
                            <v-btn dark class="primary"> RESERVE BOOK</v-btn>
                          </router-link>
                        </v-row>

                        <v-col cols="12" class="fields">
                          <label>Rack No</label>
                          <v-autocomplete dense outlined @change="getRackBlockNo()" :items="racklist" item-value="rackId"
                            item-text="rackNo" v-model="editedItem.rackId" persistent-hint></v-autocomplete>
                        </v-col>

                        <v-col cols="12" class="fields">
                          <label>Block No</label>
                          <v-autocomplete dense outlined :items="blockList" @change="checkRackAvailability()"
                            item-value="block_no" item-text="block_no" v-model="editedItem.block_no"
                            persistent-hint></v-autocomplete>
                        </v-col>
                        <p style="color: red" v-if="!isRackAvailable">
                          Selected Rack and Block not available!!!
                        </p>

                        <v-col cols="12" class="fields">
                          <label>Withdrawal No</label>
                          <v-text-field dense outlined v-model="editedItem.w_no" item-text="roleLink_list_id"
                            item-value="id" persistent-hint></v-text-field>
                        </v-col>

                        <v-col cols="12" class="fields">
                          <label>Withdrawal Date</label>
                          <v-menu v-model="showPicker5" :close-on-content-click="false" transition="scale-transition"
                            offset-y full-width max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="editedItem.w_date" placeholder="Select Date" hint="YYYY/MM/DD"
                                persistent-hint dense readonly outlined v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.w_date" no-title
                              @input="showPicker1 = false"></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" class="fields">
                          <label>Publication Place</label>
                          <v-text-field dense outlined v-model="editedItem.p_place" item-text="roleLink_list_id"
                            item-value="id" persistent-hint></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                    <v-btn class="button" @click="save()" :disabled="!valid || !isRackAvailable">Save</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <br />
          <hr />
          <v-row justify="space-between" class="pr-10 pa-4">
            <div class="">
              Total No of Available Copies:{{ bookList.length }}
            </div>
            <div>
              <span style="
                  width: 20px;
                  height: 20px;
                  color: red;
                  background-color: red;
                ">
                XXXX</span>
              Lost
              <span style="
                  width: 20px;
                  height: 20px;
                  color: #2196f3;
                  background-color: #2196f3;
                ">
                XXXX</span>
              Reserved
              <span style="
                  width: 20px;
                  height: 20px;
                  color: green;
                  background-color: green;
                ">
                XXXX</span>
              Issued
            </div>
          </v-row>

          <tr class="t-row">
            <div>
              <th class="d-flex">
                <span v-for="header in headersMember" :key="headersMember.text">
                  <span v-if="filters.hasOwnProperty(headersMember.value)">
                    <v-autocomplete class="m-2" flat hide-details small dense outlined :label="headersMember.text"
                      multiple clearable :items="columnValueList(headersMember.value)"
                      v-model="filters[headersMember.value]"></v-autocomplete>
                  </span>
                </span>
              </th>
            </div>
          </tr>
        </template>
        <template v-slot:item.date_of_entry="{ item }">
          {{
            item.date_of_entry.split("T")[0].split("-")[2] +
            "-" +
            item.date_of_entry.split("T")[0].split("-")[1] +
            "-" +
            item.date_of_entry.split("T")[0].split("-")[0]
          }}
        </template>
        <template v-slot:item.action="{ item }">
          <div><v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon></div>
        </template>
      </v-data-table>

</div>
</div><br>
  </v-card>
    <!-- <v-loader :value="loader"> Loading Please Wait.. </v-loader> -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css";
export default {
  data: () => ({
    utype: "",
    titlevalue: "",
    mediumlist: [],
    valid: false,
    authorlist_new: [],
    init_loading: false,
    selectedauthor1: "",
    selectedtitle1: "",
    selectedmedium: "",
    singleExpand: false,
    expanded: [],
    color: "green",
    loader: false,
    selected_author: [],
    snackbar_msg: "",
    snackbar: false,
    pagination: {
      sortBy: "name",
    },
    errorMsg: {
      roleLinkId: false,
    },
    dialog: true,
    selected: [],
    showPicker5: false,
    seriesbookcount: 0,
    departmentbookcount: 0,
    count: 0,
    keyword: null,
    picked: "Accession No",
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'OPAC 2',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
    headers: [
      { text: "Actions", value: "action", sortable: false },
      { text: "Photo", value: "acc_no", sortable: false },
      { text: "Sr No", value: "srno" },
      { text: "Date Of Entry", value: "date_of_entry" },
      { text: "Accession No.", value: "access_no" },
      { text: "Series", value: "series" },
      // { text: "ISBN", value: "isbn" },
      { text: "Title", value: "title" },
      { text: "Authors", value: "authors" },
      { text: "Publisher", value: "publisher" },
      { text: "Medium", value: "medium" },
      { text: "Keyword", value: "keyword" },
      { text: "Subject", value: "subject" },
      { text: "Classification no", value: "cno" },
      { text: "Edition", value: "edition" },
      { text: "Status", value: "book_status" },
      { text: "Issue Person Name", value: "issuepersonname" },
      { text: "Policy Type", value: "policy_type" },
      { text: "Book Type", value: "bookType" },
      { text: "Library Department", value: "department" },
      { text: "Copy Cost", value: "copy_cost" },
      { text: "Discount Cost", value: "discount_cost" },
      // { text: "Organization", value: "organization" },
      { text: "Rack No", value: "rackNo" },
      { text: "Block No", value: "block_no" },
    ],
    headersMember: [
      { text: "Sr No", value: "srno" },
      { text: "Photo", value: "acc_no", sortable: false },
      { text: "Date Of Entry", value: "date_of_entry" },
      { text: "Accession No.", value: "access_no" },
      { text: "Series", value: "series" },
      // { text: "ISBN", value: "isbn" },
      { text: "Title", value: "title" },
      { text: "Authors", value: "authors" },
      { text: "Publisher", value: "publisher" },
      { text: "Medium", value: "medium" },
      { text: "Keyword", value: "keyword" },
      { text: "Classification no", value: "cno" },
      { text: "Edition", value: "edition" },
      { text: "Status", value: "book_status" },
      { text: "Issue Person Name", value: "issuepersonname" },
      { text: "Policy type", value: "policy_type" },
      { text: "Book Type", value: "bookType" },
      { text: "Library Department", value: "department" },
      { text: "Copy Cost", value: "copy_cost" },
      { text: "Discount Cost", value: "discount_cost" },
      // { text: "Organization", value: "organization" },
      { text: "Rack No", value: "rackNo" },
      { text: "Block No", value: "block_no" },
    ],
    fields: {
      "Sr No": "srno",
      "Accession No": "access_no",
      "Date Of Entry": "date_of_entry",
      ISBN: "isbn",
      Title: "title",
      Authors: "authors",
      Status: "book_status",
      "Issue Person Name": "issuepersonname",
      "policy type": "policy_type",
      Series: "series",
      Edition: "edition",
      Keyword: "keyword",
      // Organization: "organization",
      "Book Type": "bookType",
      Department: "department",
      Publisher: "publisher",
      "Rack No": "rackNo",
      "Block No": "block_no",
    },
    search: "",
    filters: {
      accession_no: [],
      isbn: [],
      title: [],
      status: [],
      department: [],
      publisher: [],
      authors: [],
      // organization: [],
      series: [],
    },
    bookList: [],
    filterArr: [],
    // organizationname: "",
    publisherlist: [],
    blockList: [],
    inSave: true,
    editedIndex: -1,
    editedItem: {
      w_date: "",
      w_no: "",
      p_place: "",
      isactive: false,
      id: "",
      access_no: null,
      barcode: null,
      book_status: null,
      borrowed_date: null,
      due_date: null,
      rackNo: null,
      block_no: null,
      book_statusId: null,
      bookStatus: null,
      member: null,
      memnerId: null,
      rackId: null,
    },
    dialog: false,
    isRackAvailable: true,
    role_list: [],
    racklist: [],
    bookStatus: [],
    departmentlist: [],
    autherlist: [],
    titlelist: [],
    isbnlist: [],
    serieslist: [],
    allBookInfo: [],
    selectedpublisher: "ALL",
    selecteddepartment: "ALL",
    selectedauther: "ALL",
    selectedtitle: "ALL",
    selectedisbn: "ALL",
    selectedseries: "ALL",
    image_url: "",
    selectedsubject1: "",
    selectedkeyword1: "",
  }),

  computed: {
    bookList1() {
      return this.bookList.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Book Item" : "Edit Book Item";
    },
  },

  mounted() {
    this.init_loading = true;
    axios.post("/SearchCatalog/getBookFilterSeries").then((res) => {
      if (res.data.msg == "200") {
        this.init_loading = false;
        this.departmentlist = res.data.departmentlist;
        this.serieslist = res.data.serieslist;
        this.mediumlist = res.data.mediumlist;
        this.seriescount();
        setTimeout(() => {
          this.departmentcount();
        }, 300);
      }
    });
  },

  methods: {
    cancelphoto() {
      this.image_url = "";
    },
    openphoto(value) {
      this.image_url = "";

      var params = { "acc_no": value.access_no };
      axios
        .post("/Librarian/getimageurl", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.image_url = res.data.url;

          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var loader = false;
        });

    },
    settext1() {
      this.keyword = this.selectedmedium.name;
    },
    setmedium(value) {
      this.keyword = value;

    },
    settext2() {
      this.keyword = this.selectedauthor1.name;
    },
    settext() {
      this.keyword = this.selectedtitle1.displayname;

    },
    settextK() {
      this.keyword = this.selectedkeyword1.displayname;
    },
    settextS() {
      this.keyword = this.selectedsubject1.displayname;
    },

    onload2(value) {
      this.keyword = value;
      this.titlevalue = value;

      if (value != "") {
        var params = { text: value, seaechbyacc: "author" };
        axios
          .post("/IssueBook/getauthor_new", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.authorlist_new = res.data.allBookInfo;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
          })
          .finally(() => {
            // var loader = false;
          });
      }
    },

    onload1(value) {
      this.keyword = value;
      this.titlevalue = value;


      if (value != "") {
        var params = { text: value, seaechbyacc: "title" };
        axios
          .post("/IssueBook/getsearchbytitle", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.allBookInfo = res.data.allBookInfo;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
          })
          .finally(() => {
            // var loader = false;
          });
      }
    },
    onloadkeyword(value) {
      // alert("in onloadkeyword");
      this.keyword = value;
      this.titlevalue = value;


      if (value != "") {
        var params = { text: value, seaechbyacc: "key" };
        axios
          .post("/IssueBook/getkeyword", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.allBookInfo = res.data.allBookInfo;

            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
          })
          .finally(() => {
            // var loader = false;
          });
      }
    },
    onloadsubject(value) {
      //  alert("in onloadsubject");
      this.keyword = value;
      this.titlevalue = value;


      if (value != "") {
        var params = { text: value, seaechbyacc: "subject" };
        axios
          .post("/IssueBook/getsubject", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.allBookInfo = res.data.allBookInfo;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
          })
          .finally(() => {
            // var loader = false;
          });
      }
    },

    row_classes(item) {
      if (item.book_status == "Issued") {
        return "green";
      }
      if (item.book_status == "Lost") {
        return "red";
      }
      if (item.book_status == "Weed Out") {
        return "orange";
      }
      if (item.book_status == "Reserved") {
        return "blue";
      }
    },
    editItem(item) {
      this.blockList.push({ block_no: item.block_no });
      this.inSave = false;
      this.editedIndex = this.bookList1.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      var name = item.role;
      for (let x in this.role_list) {
        if (this.role_list[x].name == name) {
          this.editedItem.role = this.role_list[x].name;
          this.editedItem.roleId = this.role_list[x].id;
          break;
        }
      }
    },
    save() {
      this.editedItem.due_date = moment(
        String(this.editedItem.due_date)
      ).format("DD-MM-YYYY");
      this.editedItem.borrowed_date = moment(
        String(this.editedItem.borrowed_date)
      ).format("DD-MM-YYYY");
      if (this.editedIndex > -1) {
        if (this.$refs.form.validate()) {
          axios
            .post("/Librarian/editBookItem", this.editedItem)
            .then((res) => {
              if (res.data.msg == "success") {
                this.showSnackbar("#4caf50", "Book Updated Successfully...");
                this.searchBykeyword();
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "Book Already Present!!!");
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      }
    },

    checkRackAvailability() {
      const data = {
        rackId: this.editedItem.rackId,
        block_no: this.editedItem.block_no,
      };
      axios
        .post("/Librarian/checkRackAvailability", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.isRackAvailable = res.data.isRackAvailable;
          } else if (res.data.status.code == "NA") {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    onlyUnique(value, index, self) {
      if (value != null && value != "" && value)
        return self.indexOf(value) === index;
    },
    columnValueList(val) {
      var data = this.bookList.map((d) => d[val]);
      data = data.filter(this.onlyUnique);
      return data;
    },
    getFiltered(e) {
      console.log(e);
      this.filterArr = e;
    },
    searchBykeyword(flag) {
      if (flag) {
        this.count = 0;
        this.bookList = [];
      }
      if (this.picked == "Book Title") {
        if (typeof this.selectedtitle1 === 'object')
          this.keyword = this.selectedtitle1.displayname;
        else
          this.keyword = this.selectedtitle1
      }
      else if (!this.keyword)
        this.keyword = this.titlevalue;

      const data = {
        keyword: this.keyword,
        selecteddepartment: this.selecteddepartment,
        selectedseries: this.selectedseries,
        picked: this.picked,
        count: this.count,
      };

      if (this.count == 0) this.loader = true;
      axios
        .post("/SearchCatalog/getBookItemDataSeries", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.loader = false;
            if (this.count == 0) {
              this.expanded = [];
              // this.organizationname = res.data.organizationid;
              // this.filters.organization = [];
              this.utype = res.data.utype;
              // this.filters.organization.push(this.organizationname);
              this.bookList = res.data.bookList;

              this.racklist = res.data.racklist;
              this.bookStatus = res.data.bookStatus;
              if (res.data.bookList.length != 0) {
                this.count = this.count + 500;
                this.searchBykeyword(false);
              }
            } else {
              if (this.count != 0) {
                this.bookList.push.apply(this.bookList, res.data.bookList);
                if (res.data.bookList.length != 0) {
                  this.count = this.count + 500;
                  this.searchBykeyword(false);
                }
              }
            }
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong...");
          window.console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    setValue() {
      if (this.selectedseries != "ALL" && this.picked == "Accession No") {
        for (var k = 0; k < this.serieslist.length; k++) {
          if (this.serieslist[k].id == this.selectedseries)
            this.keyword = this.serieslist[k].short_name;
        }

      }
      this.allBookInfo = [];
    },

    seriescount() {
      const data = {
        selectedseries: this.selectedseries,
      };

      axios
        .post("/SearchCatalog/getSeriesBookCount", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.seriesbookcount = res.data.seriesbookcount;
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    departmentcount() {
      const data = {
        selecteddepartment: this.selecteddepartment,
      };

      axios
        .post("/SearchCatalog/getDepartmentBookCount", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.departmentbookcount = res.data.departmentbookcount;
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    isValidated() {
      if (this.editedItem.roleLinkId) {
        return true;
      } else {
        if (!this.editedItem.roleLinkId) {
          this.errorMsg.roleLinkId = true;
        }
        return false;
      }
    },

    getRackBlockNo() {
      const data = {
        rackId: this.editedItem.rackId,
        block_no: this.editedItem.block_no,
      };
      axios
        .post("/Librarian/getRackBlockNo", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.blockList = res.data.blockList;
            this.isRackAvailable = res.data.isRackAvailable;
          } else if (res.data.status.code == "NA") {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}

.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.bookItem {
  text-decoration: underline !important;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.search_row {
  background: skyblue;
  border: 2px solid black;
}

.div.v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  color: green !important;
  background-color: aqua;
}
</style>

